<template>
  <div>

    <select v-model="selectedSocieteId" class="form-control w-auto" id="societe_id">
      <option disabled value="" selected>Sélectionnez une société</option>
      <option value="1" v-if="havePermission('manage_cse')">Energy</option>
      <option value="2" v-if="havePermission('manage_cse_techno')">Techno</option>
      <option value="13" v-if="havePermission('manage_cse_hoggar')">Hoggar</option>
    </select>

    <div v-if="havePermission('manage_cse')"  :style="{ display: selectedSocieteId === '1' ? 'block' : 'none' }">
      <div class="rounded p-3 mb-3 mt-3" v-background-3>
        <h4>Rémunération des financeurs (Energy)</h4>
        <uploader
          :id="'document_financeurs'"
          url="/upload/document"
          file_system="CSE/FINANCEURS"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>

      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Activités sociales et culturelles</h4>
        <uploader
          :id="'document_social_culturel'"
          url="/upload/document"
          file_system="CSE/SOCIAL_CULTUREL"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>

      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Partenariat</h4>
        <uploader
          :id="'document_partenariat'"
          url="/upload/document"
          file_system="CSE/PARTENARIATS"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>

      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Transfert commerciaux et financiers</h4>
        <uploader
          :id="'document_partenariat'"
          url="/upload/document"
          file_system="CSE/TRANSFERTS"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>
    </div>

    <div v-if="havePermission('manage_cse_techno')"  :style="{ display: selectedSocieteId === '2' ? 'block' : 'none' }">
      <div class="rounded p-3 mb-3 mt-3" v-background-3>
        <h4>Rémunération des financeurs (Techno)</h4>
        <uploader
          :id="'document_financeurs_techno'"
          url="/upload/document"
          file_system="CSE_TECHNO/FINANCEURS"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>

      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Activités sociales et culturelles</h4>
        <uploader
          :id="'document_social_culturel_techno'"
          url="/upload/document"
          file_system="CSE_TECHNO/SOCIAL_CULTUREL"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>

      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Partenariat</h4>
        <uploader
          :id="'document_partenariat_techno'"
          url="/upload/document"
          file_system="CSE_TECHNO/PARTENARIATS"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>

      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Transfert commerciaux et financiers</h4>
        <uploader
          :id="'document_partenariat_techno'"
          url="/upload/document"
          file_system="CSE_TECHNO/TRANSFERTS"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>
    </div>

    <div v-if="havePermission('manage_cse_hoggar')"  :style="{ display: selectedSocieteId === '13' ? 'block' : 'none' }">
      <div class="rounded p-3 mb-3 mt-3" v-background-3>
        <h4>Rémunération des financeurs (Hoggar)</h4>
        <uploader
          :id="'document_financeurs_hoggar'"
          url="/upload/document"
          file_system="CSE_HOGGAR/FINANCEURS"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>

      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Activités sociales et culturelles</h4>
        <uploader
          :id="'document_social_culturel_hoggar'"
          url="/upload/document"
          file_system="CSE_HOGGAR/SOCIAL_CULTUREL"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>

      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Partenariat</h4>
        <uploader
          :id="'document_partenariat_hoggar'"
          url="/upload/document"
          file_system="CSE_HOGGAR/PARTENARIATS"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>

      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Transfert commerciaux et financiers</h4>
        <uploader
          :id="'document_partenariat_hoggar'"
          url="/upload/document"
          file_system="CSE_HOGGAR/TRANSFERTS"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>
    </div>

  </div>
</template>
<script>
import Uploader from "@/components/bases/Uploader.vue";
import { mapGetters } from "vuex";

export default {
  components: { Uploader },
  data() {
    return {
      selectedSocieteId: '', // Ajoutez cette ligne pour définir selectedSocieteId dans le data
    };
  },
  methods: {
    initializeSelectedSocieteId() {
      if (this.havePermission('manage_cse')) {
        this.selectedSocieteId = '1';
      } else if (this.havePermission('manage_cse_techno')) {
        this.selectedSocieteId = '2';
      } else {
        this.selectedSocieteId = '13';
      }
    },
  },
  mounted() {
    this.initializeSelectedSocieteId();
  },
  computed: {
    ...mapGetters({ havePermission: "user/havePermission" }),
  },
};
</script>
